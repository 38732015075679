import React, { useLayoutEffect, useRef, useState } from "react";
import Page from "../components/Common/Page";
import IndexLayout from "../layouts";
import BannerSection from "../components/pages/PressPage/BannerSection";
import ContentSection from "../components/pages/PressPage/ContentSection";

const defaultShow = {
  itemOne: false,
  itemTwo: false,
  itemThree: false,
  itemFour: false,
  itemFive: false,
  itemSix: false,
  itemSeven: false,
};

const IndexPage = () => {
  const [check, setCheck] = useState(false);
  const [show, setShow] = useState<{ [key: string]: boolean }>({ ...defaultShow, navbar: false });

  const [activeStep, setActiveStep] = useState("#banner");

  const refNavbar = useRef(null);
  const refOne = useRef(null);
  const refTwo = useRef(null);

  useLayoutEffect(() => {
    const bottomPos = (element: any) => element?.getBoundingClientRect().bottom + window.scrollY;

    const section1Bottom = bottomPos(refOne.current);
    const section2Bottom = bottomPos(refTwo.current);

    const onScroll = () => {
      if (window) {
        const scrollPos = window.scrollY + window.innerHeight;
        // Section1
        if (scrollPos < window.innerHeight + 16) {
          if (!show["itemOne"]) {
            setShow((prev: any) => ({
              ...prev,
              ["itemOne"]: true,
              ["itemTwo"]: true,
              navbar: false,
            }));
            setActiveStep("#banner");
          }
        }
        // Section2
        else if (window.innerHeight + 16 < scrollPos && scrollPos < section2Bottom) {
          if (!show["itemTwo"]) {
            setShow((prev: any) => ({
              ...prev,
              ["itemTwo"]: true,
              navbar: true,
            }));
            setActiveStep("#productlist");
          }
        }
      }
    };

    const initialProp = () => {
      onScroll();
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll);
    };

    const changeCheck = () => {
      setCheck(!check);
    };

    if (window) {
      window.addEventListener("scroll", onScroll);
      window.addEventListener("resize", changeCheck);
      initialProp();
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", changeCheck);
    };
  }, [check]);

  return (
    <IndexLayout ref={refNavbar} animate={show.navbar}>
      <Page>
        <BannerSection ref={refOne} animate={show.itemOne} />
        <ContentSection ref={refTwo} animate={show.itemTwo} />
      </Page>
    </IndexLayout>
  );
};

export default IndexPage;
