import React, { forwardRef } from "react";
import BannerBg from "../../../assets/pages/products/BannerBg.png";
import { BannerText } from "../../Common/BannerText";

interface BannerSectionProps {
  className?: string;
  animate: boolean;
}

const BannerSection = forwardRef(({ className, animate }: BannerSectionProps, ref: any) => {
  return (
    <>
      <div
        className={`relative flex justify-center w-screen h-[352px] sm:h-[200px] md:h-[300px] xl:h-[440px] bg-center bg-cover bg-no-repeat`}
        ref={ref}
        id="banner"
        style={{
          background: "#C5CCC3",
        }}
      >
        <div className="sm:mx-8 lg:mx-16 xl:mx-28 2xl:mx-auto max-w-screen-lg h-full xl:max-w-screen-xl mx-auto z-20">
          <BannerText animate={animate}>Press & Media</BannerText>
        </div>
      </div>
    </>
  );
});

export default BannerSection;
