import { navigate } from "gatsby";
import React, { forwardRef } from "react";
import Press1 from "../../../assets/pages/press/Press1.png";
import Press2 from "../../../assets/pages/press/Press2.png";
import Press3 from "../../../assets/pages/press/Press3.png";
import Press4 from "../../../assets/pages/press/Press4.png";
import Press5 from "../../../assets/pages/press/Press5.png";
import Press6 from "../../../assets/pages/press/Press6.png";
import { PrimaryButton } from "../../Common/Button";

interface ContentSectionProps {
  className?: string;
  animate?: boolean;
}

type FeatureProps = {
  id: string;
  subTitle: string;
  title: string;
  excerpt?: string;
  url: string;
  image: string;
};

const CollectionsData: FeatureProps[] = [
  {
    id: "press1",
    subTitle: "2022 - ฐานเศรษฐกิจดิจิทัล",
    title: "เบอร์ 1 ต้นน้ำ “กัญชา-กัญชง” เมดิคาน่า แล็บ ทุ่ม 120 ล้าน ชู Smart farming",
    url: "https://www.thansettakij.com/business/523267",
    image: Press1,
  },
  {
    id: "press2",
    subTitle: "2022 - NATION THAILAND",
    title: "Heirs of the Phornprapha and Praneenij Family InvestTHB120M",
    url: "https://www.nationthailand.com/pr-news/business/40014989",
    image: Press2,
  },
  {
    id: "press3",
    subTitle: "2022 - BANGKOK POST",
    title: "Medicana to invest B120m in hemp farm",
    url: "https://www.bangkokpost.com/business/2301486/medicana-to-invest-b120m-in-hemp-farm",
    image: Press3,
  },
  {
    id: "press4",
    subTitle: "2022 - BLOCKDIT",
    title: "“เมดิคาน่า แล็บ” ชูเทคโนโลยี smart farming รุกธุรกิจกัญชา-กัญชง",
    url: "https://www.blockdit.com/posts/626a38243790930317962b2d",
    image: Press4,
  },
  {
    id: "press5",
    subTitle: "2022 - MANAGER ONLINE",
    title: "“เมดิคาน่า แล็บ” รุกธุรกิจกัญชา-กัญชง เอาใจสายเขียว",
    url: "https://mgronline.com/entertainment/detail/9650000040150",
    image: Press5,
  },
  {
    id: "press6",
    subTitle: "2022 - กรุงเทพธุรกิจ",
    title: "ไอเดียนิวเจน‘เมดิคาน่าแล็บ’ ลุยธุรกิจกัญชา-กัญชง",
    url: "https://www.bangkokbiznews.com/business/1001609",
    image: Press6,
  },
];

const ContentSection = forwardRef(({ className, animate }: ContentSectionProps, ref: any) => {
  return (
    <section className={"relative h-auto flex flex-col items-center px-4 lg:px-16 py-10 md:py-20"} ref={ref} id="capabilities">
      {/* <h3 className="font-semibold sm:font-normal lg:text-h2 sm:text-b1 text-b2 h4 mb-10 md:mb-18 mt-0 text-woodyGreen">
        Premium Cultivars with Passion
      </h3> */}
      <div
        className="grid grid-cols-12 w-full px-4 md:px-8 max-w-screen-xl 
      gap-x-4 md:gap-x-10 lg:gap-y-[120px] md:gap-y-[80px] sm:gap-y-[40px] gap-y-6"
      >
        {CollectionsData.map((data: FeatureProps) => (
          <div className="col-span-12 md:col-span-6 lg:col-span-4 flex flex-col">
            <img
              src={data.image}
              className="w-full xl:h-[312px] lg:h-[270px] md:h-[300px] sm:h-[300px] h-[260px] rounded-[20px] object-cover mb-4 md:mb-10"
            />
            <div className="relative h-[170px] md:h-[190px] lg:h-[240px] flex flex-col gap-y-0 rounded-[20px]">
              <p className="md:text-[16px] text-[14px] text-medGrey md:mb-2 mb-1" style={{ fontFamily: "Noto Sans Thai" }}>
                {data.subTitle}
              </p>
              <h3
                className={`font-semibold lg:text-[31px] lg:leading-10 text-b2 text-medMainGreen md:mb-4 mb-2`}
                style={{ fontFamily: "Noto Sans Thai" }}
              >
                {data.title}
              </h3>
              {/* <p className="md:text-cap text-[18px] text-medMainGreen mb-4 md:mb-6 lg:mb-10">{data.excerpt}</p> */}
              <PrimaryButton style={{ position: "absolute", bottom: 0, left: 0 }} onClick={() => navigate(data.url)}>
                Read More
              </PrimaryButton>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
});

export default ContentSection;

// <div
//   className="flex justify-center items-start h-[128px] md:h-[240px] col-span-6 md:col-span-3
//   p-2 mb-10 md:p-6 md:px-0 xl:px-3
//   !bg-center !bg-cover !bg-no-repeat"
//   key={data.title}
// >
//   <div className="p-0 z-10 transition-all flex flex-col items-center justify-start">
//     <img src={data.image} className="w-3/4 md:w-[200px] mb-2 md:mb-6" />
//     <h3 className="font-extralight mb-0 mt-0 text-xl md:text-2xl text-medMainGreen text-center">{data.title}</h3>
//   </div>
// </div>
